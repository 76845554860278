import {required} from 'vuelidate/lib/validators'
import { mapGetters,} from 'vuex';

export default {
    props: ['popupType', 'selectedItem'],
    data() {
        return {
            form: {
                monobank_title: 'Monobank',
                monobank_module: 'Monobank',
                monobank_description: 'Монобанк апі токен',
                monobank_status: 1,
                monobank_token: '',
            },
        }
    },

    validations: {
        form: {
            np_title: {
                required
            }
        }
    },
    computed: {
        ...mapGetters({
            options:'config/options',
        }),
    },
    created() { 
        this.form.monobank_token = this.options.models.monobank_token;
    },
    methods: {
        closePopup() {
            this.$emit('closePopup')
        },
        sendConfirm() {
            if(this.warehouse){
                this.$emit('changeOptions', this.form)
            }else{
                this.$emit('sendOptions', this.form)
            }
        },
    },
    destroyed() {
        this.changeShowWarehouse(null);
    }
}
